/* WhyToBuyUs.module.css */

.container {
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 2px 10px;
  color: var(--text-color);
  text-align: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.mainHeader {
  font-size: 2.75rem;
  color: var(--highlight);
  margin-bottom: 2px;
}

.intro {
  font-size: 1.4rem;
  margin-bottom: 5px;
}

.reasons {
  /* background-color: var(--bg-section); */
  padding: 25px;
  border-left: 6px solid var(--main-color);
  border-radius: 8px;
  margin: 0 auto 40px;
  max-width: 800px;
  text-align: left;
}

.reasonList {
  list-style-type: disc;
  padding-left: 30px;
}

.reasonList li {
  margin: 12px 0;
  font-size: 1.1rem;
}

.buttonContainer {
  margin: 30px 0;
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
}

.primaryButton, .secondaryButton {
  background-color: var(--accent-color);
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  box-shadow: 2px 2px 0 #000;
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.secondaryButton {
  background-color: #e6b325;
}

.primaryButton:hover, .secondaryButton:hover {
  background-color: #d97706;
  transform: scale(1.05);
}

.reviewsSection {
  margin-top: 60px;
}

.reviewsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.reviewsScroll {
  display: flex;
  overflow-x: auto;
  gap: 15px;
  padding: 15px;
  scroll-behavior: smooth;
}

.reviewCard {
  background-color: white;
  border: 1px solid #e0e0e0;
  padding: 20px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  width: 90%;
  max-width: 280px;
  border-radius: 10px;
  flex-shrink: 0;
  transition: transform 0.2s;
}

.reviewCard:hover {
  transform: translateY(-4px);
}

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--highlight);
  margin-bottom: 10px;
}

.reviewContent h3 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 5px 0 0;
}

.reviewContent h4 {
  font-size: 0.95rem;
  font-weight: 400;
  color: #666;
}

.reviewContent h5 {
  font-size: 0.85rem;
  color: #999;
}

.reviewContent p {
  margin-top: 10px;
  font-size: 0.95rem;
  color: #333;
  text-align: left;
}

.arrowButton {
  background-color: var(--button-color);
  border: none;
  color: var(--second-color);
  font-size: 1.8rem;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 2px 2px 0 #000;
  cursor: pointer;
}

.arrowButton:hover {
  background-color: var(--button-hover-color);
}

.comicContainer {
  position: relative;
  width: 768px;
  height: 768px;
  font-family: 'Comic Sans MS', sans-serif;
}

.comic-background {
  width: 100%;
  height: 100%;
  display: block;
}


.reasonRow,
.reasonRowReverse {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 2rem 0;
}

.reasonRowReverse {
  flex-direction: row-reverse;
}

.reasonText {
  flex: 1;
  font-size: 1.2rem;
  line-height: 1.5;
}

.reasonImage {
  flex: 1;
  max-width: 400px;
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}


@media (max-width: 768px) {
  .arrowButton {
    display: none;
  }

  .mainHeader {
    font-size: 2rem;
  }

  .intro {
    font-size: 1.2rem;
  }

  .reasonText {
    font-size: 1rem;
    line-height: 1.0;
  }
}
