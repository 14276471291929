.loadingContainer {
    background-color: var(--background-color);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loadingText {
    color: var(--main-color);
    font-size: 1.5rem;
  }
  